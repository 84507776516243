import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        background: '#00254a',
        padding: '190px 24px 120px 24px',
        marginTop: -120,
      }}
    >
      <h1>Page Not found</h1>
      <p>We're sorry, the page you are looking for could not be found.</p>

      <Link to="/" style={{ marginTop: 24 }}>
        Go back to the homepage
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
